<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>الأرشيف</h3>
      <div>
        <b-button variant="primary" @click="onAdd()">
          <feather-icon icon="userIcon" /> إضافة
        </b-button>
      </div>
    </div>
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="4">
          <form-select
            label=""
            v-model="category_id"
            placeholder="إختر نوع ..."
            class="w-50"
            :reduce="(x) => x.id"
            getOptionLabel="(x) => x.title"
            id="archive-categories_id"
            ep="select/archive-categories"
            rules="required"
          />
        </b-col>
        <b-col cols="4">
          <form-input label="" v-model="filter" placeholder="ابحث هنا..." class="w-50" />
        </b-col>
      </b-row>
    </div>
    <data-table
      ref="estatesTable"
      :fields="fields"
      ep="/archives"
      :filter.sync="filter"
      :utils="{ category_id }"
    >
      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button
            variant="flat-info"
            title="عرض المزيد من المعلومات"
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            v-b-tooltip.hover.v-info.top
            @click="row.toggleDetails"
            v-b-toggle.collapse-1
          >
            المزيد <feather-icon icon="ChevronDownIcon" />
            <!-- ChevronUpIcon -->
          </b-button>
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="onUpdateClicked(row.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onDelete(row.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="4">
              <b-img :src="row.item.image" class="w-100 h-100" />
            </b-col>
            <b-col cols="8">
              <h6 class="text-primary">التفاصيل</h6>
              <p>
                {{ row.item.description }}
              </p>
            </b-col>
            <b-col cols="12">
              <h6 class="text-primary mt-3">الكلمات المفتاحية</h6>
              <div class="demo-inline-spacing">
                <b-badge
                  variant="light-primary mt-1"
                  v-for="(item, id) in row.item.archive_tags"
                  :key="id"
                >
                  {{ item }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </template>
    </data-table>
    <form-modal
      ref="estateModalArchives"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
      <template #cost="item">
        <form-mask
          v-if="item.form.is_payment"
          label="الكلفة"
          options="number"
          rules="required"
          dir="ltr"
          placeholder="1,000"
          v-model="item.form.cost"
        />
      </template>
      <template #payment_method="item">
        <form-select
          v-if="item.form.is_payment"
          v-model="item.form.payment_method"
          id="payment_method"
          label="طريقة الدفع"
          rules="required"
          :reduce="(el) => el.value"
          :options="[
            { label: 'إلكتروني', value: 'E' },
            { label: 'كاش', value: 'C' },
          ]"
        />
      </template>
      <template #reservation_information="item">
        <form-textarea
          v-if="item.form.is_reservation"
          label="تفاصيل الحجز"
          rules="required"
          v-model="item.form.reservation_information"
        />
      </template>

      <template #image="{ form }">
        <form-file
          label="الصورة الخارجية"
          accept="image/*"
          v-model="image"
          id="form-file-archives"
          name="image"
          placeholder="لم تقم باختيار صور"
          @change="previewImage"
        />
        <div class="text-center mb-1">
          <layout-input-image
            v-if="staticImages.url"
            :url="staticImages.url"
            :name="''"
            :is_last_imge="false"
            @deleteItem="deleteImage"
          >
          </layout-input-image>
          <layout-input-image
            v-if="!staticImages.url && form.image"
            :url="form.image"
            :name="''"
            :is_last_imge="false"
            :is_delete="false"
          />
        </div>
      </template>
      <template #file>
        <form-file
          label=" محتوى الأرشيف"
          accept="image/*"
          id="archives-file"
          name="file"
          v-model="file"
          placeholder="لم تقم باختيار أرشيف"
        />
        <div v-if="file == null" class="border rounded-lg text-center mb-1">
          <feather-icon icon="FileIcon" class="my-3" size="30" />
        </div>
        <div v-else class="border rounded-lg py-1">
          <b-row align-v="center">
            <b-col cols="10">
              <div class="mx-1">{{ file.name }}</div>
            </b-col>
            <b-col cols="2">
              <feather-icon icon="FileIcon" class="my-50 text-primary" size="30" />
            </b-col>
          </b-row>
        </div>
      </template>
    </form-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BCollapse,
  VBToggle,
  BRow,
  BCol,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BDropdown,
  BDropdownItem,
  BBadge,
} from "bootstrap-vue";
import FormModal from "@/components/FormModal.vue";
import DataTable from "@/components/data-table/index";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapActions, mapGetters } from "vuex";
import FormFile from "@/components/form-file/index.vue";
import FormInput from "@/components/form-input/index";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import FormTextarea from "@/components/form-textarea";
import FormMask from "@/components/form-mask/index.vue";
import FormSelect from "@/components/form-select/index.vue";
import IconMedia from "@/components/icon-media.vue";
import LayoutInputImage from "@/components/LayoutInputImage.vue";

export default {
  components: {
    FormTextarea,
    FormMask,
    FormSelect,
    BCard,
    BButton,
    DataTable,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BCollapse,
    FormModal,
    FormFile,
    BRow,
    BCol,
    FormInput,
    BCarousel,
    BCarouselSlide,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BImg,
    IconMedia,
    BDropdown,
    BDropdownItem,
    LayoutInputImage,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data: () => {
    return {
      file: null,
      image: null,
      category_id: "",
      fields: [
        { key: "title", label: "الأرشيف", sortable: true },
        { key: "date", label: "التاريخ" },
        { key: "place", label: "المكان" },
        { key: "actions", label: "" },
      ],

      activModel: false,
      cost: "",
      payment_method: "",
      filter: "",
      reservation_information: "",
      staticImages: {
        url: "",
        name: "",
      },
      estatesFormSchema: {
        title: {
          component: "FormInput",
          key: "title",
          attrs: {
            label: "الأرشيف",
            rules: "required",
          },
        },
        archive_category_id: {
          component: "FormSelect",
          key: "archive_category_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.title,
            id: "archive_category_id",
            label: "الفئة",
            ep: "select/archive-categories",
            rules: "required",
          },
        },
        tags: {
          component: "FormSelect",
          key: "tags",
          attrs: {
            label: "الكلمات المفتاحية",
            multiple: true,
            taggable: true,
            "push-tags": true,
          },
        },
        date: {
          component: "FormDatepicker",
          key: "date",
          attrs: {
            id: "date_archives",
            label: "تاريخ",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        place: {
          component: "FormInput",
          key: "place",
          attrs: {
            label: "المكان",
            rules: "required",
          },
        },
        description: {
          component: "FormTextarea",
          key: "description",
          attrs: {
            label: "التفاصيل",
            rules: "required",
          },
        },
        image: {
          key: "image",
        },
        file: {
          key: "file",
        },
      },
    };
  },
  methods: {
    ...mapActions("manageApp/archives", [
      "deleteArchives",
      "createArchives",
      "updateArchives",
    ]),

    deleteImage() {
      this.image = null;
      this.staticImages.url = "";
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الأرشيف", {
          title: "تأكيد حذف الأرشيف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteArchives({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        title: form.title,
        description: form.description,
        place: form.place,
        date: form.date,
        archive_category_id: form.archiveCategory.id,
        tags: form.archive_tags,
      };
      this.$refs.estateModalArchives.init(toUpdate);
      this.activModel = true;
      this.file = null;
      this.image = null;
    },

    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (key == "tags") {
            for (let i = 0; i <= element.length; i++) {
              if (element[i]) fd.append("tags[]", element[i]);
            }
          } else {
            fd.append(key, element);
          }
        }
      }
      if (this.file) fd.append("file", this.file);
      if (this.image) fd.append("image", this.image);

      if (form.id) {
        fd.append("_method", "PUT");
        this.updateArchives({ id: form.id, fd: fd }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createArchives({ fd: fd }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
    onAdd() {
      this.$refs.estateModalArchives.init({});
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
      this.deleted_last_images = [];
      this.file = null;
      this.image = null;
      this.activModel = true;
    },

    previewImage(e) {
      this.staticImages = {
        url: URL.createObjectURL(e.target.files[0]),
        name: e.target.files[0].name,
      };
    },
  },
  watch: {
    category_id(val) {
      this.$refs.estatesTable.refreshTable();
    },
  },
  computed: {
    ...mapGetters("manageApp/archives", ["loading"]),
  },
};
</script>
